import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'dashboard/src/theme';
import Page from 'dashboard/src/components/Page';
import _ from 'lodash';
import { useIframeListener } from 'dashboard/src/hooks/useIframeListener';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

interface IFrameContentProps {
  iframeUrl: string;
  baseUrl?: string;
  listener?: (event: Record<string, any>) => void,
  signingOrderId?: any;
  appliedTemplates?: any;
}

const IframeContent = forwardRef<HTMLIFrameElement, IFrameContentProps>(({ iframeUrl, listener = _.identity, signingOrderId, baseUrl = (window as any).baseUrl }, ref) => {
  const classes = useStyles();

  useIframeListener(listener)
  const url = `${baseUrl}${iframeUrl}`;
  const addSigningId = signingOrderId ? "?data=" + signingOrderId : '';

  return (
    <Page
      className={classes.root}
      title='Notarization'
      data-testid='iframe'
    >

      <iframe
        ref={ref}
        src={url + addSigningId}
        allow='camera;microphone'
        id='iframe'
        title='iframe'
        frameBorder='0'
        style={{
          width: '100%',
          display: 'block',
          border: 'none',
          marginBottom: '0',
          padding: '0',
          flex: 'auto',
          flexDirection: 'column'
        }}
        onLoad={async () => {
          const nr = document.getElementById('iframe') as unknown as HTMLIFrameElement
          nr.contentWindow.postMessage({
            token: await (window as any).auth0.getTokenSilently()
          }, baseUrl);
        }}
      />
    </Page>
  );
});

export default IframeContent;
