import { useRef } from 'react';

type IframeMessageProps = {
  formatter?: (payload: any) => any;
};

export const useIframeMessage = ({ formatter }: IframeMessageProps = {}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const sendMessage = (message: any) => {
    const contentIFrame = iframeRef.current;

    if (contentIFrame) {
      const formattedMessage = formatter ? formatter(message) : message;

      contentIFrame.contentWindow.postMessage(
        formattedMessage,
        (window as any).baseUrl
      );
    }
  };

  return {
    iframeRef,
    sendMessage,
  };
};
