import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  doNotExceedTabHeight: {
    '& input[type="text"]': {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
  },
}));
