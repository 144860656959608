import { FC, useCallback, useRef, useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles'
import { ComponentProps } from './types';
import { usePrevious } from 'react-use';

const Component: FC<ComponentProps> = ({ onSearch, currentTab, ...props }) => {
  const classes = useStyles();

  // If either value or onChange are undefined, then use internalValue instead. If they are provided, use what's provided from the parent
  const [internalValue, setInternalValue] = useState('');
  const internalOnChange = useCallback((e) => {
    setInternalValue(e.target.value);
    onSearch(e.target.value);
  }, [onSearch]);
  const isControlledInput = props.value !== undefined && props.onChange !== undefined;
  const value = isControlledInput ? props.value : internalValue;
  const onChange = isControlledInput ? props.onChange : internalOnChange;


  const prevTab: string = usePrevious(currentTab);

  if (prevTab && currentTab !== prevTab) {
    onSearch(value);
  }

  return (
    <TextField
      fullWidth={true}
      {...props}
      value={value}
      onChange={onChange}
      placeholder={props.isDashboard ? 'Search customers' : 'Search'}
      className={clsx(props.className, classes.doNotExceedTabHeight)}
      InputProps={{
        ...props.InputProps,
        startAdornment: props.noIcon ? '' : (
          <InputAdornment position='start'>
            {/* <SvgIcon
              fontSize='small'
              color='action'
            >
              <Search />
            </SvgIcon> */}
          </InputAdornment>
        )
      }}
      variant='filled'
      color='secondary'
    />
  );
}

export default Component;
