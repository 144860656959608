import { AxiosInstance } from 'axios';
import { DownloadOpts } from 'dashboard/src/types/download';

export const getNsDownloadLinks = async (
  axios: AxiosInstance,
  nsId: string,
  downloadOpts: DownloadOpts,
): Promise<Record<string, string>> => {
  // FIXME: this env link will change guaranteed
  const links = await axios.get(`/document/${nsId}/download-links`);
  const linksObj = {};
  downloadOpts.forEach((opt) => {
    // FIXME: this env link will change guaranteed
    linksObj[opt] = `${links.data[opt]}`
  });
  return linksObj;
}
