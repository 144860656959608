import React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import type { Theme } from 'dashboard/src/theme';
import HeaderCrumbs from 'dashboard/src/components/HeaderCrumbs';

interface PageHeaderProps {
  className?: string;
  pageTitle: string;
  breadCrumbs: { path?: string; label: string }[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const PageHeader: FC<PageHeaderProps> = ({ className, breadCrumbs, pageTitle, children }) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justifyContent='space-between'
      spacing={3}
    >
      <Grid item>
        <HeaderCrumbs
          items={breadCrumbs}
          title={pageTitle}
        />
      </Grid>
      <Grid item>
        {children}
      </Grid>
    </Grid>
  );
};

export default PageHeader;
