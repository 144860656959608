import { createSelector } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selector, getNotarySessions, cancelNotarySession, setSelectedSession } from 'dashboard/src/slices/notary-sessions';



const sessionsSel = createSelector(selector, (state) => state.sessions.byStatus);
const selectedSessionSel = createSelector(selector, (state) => state.sessions.selectedSession);

export default function useNotarySessions() {
  const dispatch = useDispatch();
  const sessions = useSelector(sessionsSel);
  const selectedSession = useSelector(selectedSessionSel);

  const getSessions = useCallback((tab, page, limit) => dispatch(getNotarySessions({ tabName: tab.value, statuses: tab.statuses, notarizedByMe: tab.notarizedByMe, createdAfter: tab.createdAfter }, { page, limit })), [dispatch]);
  const cancelSession = useCallback((id, tab, page, limit) => dispatch(cancelNotarySession(id, { tabName: tab.value, statuses: tab.statuses }, { page, limit })), [dispatch])

  const selectSession = useCallback((id: string) => dispatch(setSelectedSession(id)), [dispatch]);

  return {
    sessions,
    getSessions,
    selectSession,
    selectedSession,
    cancelSession
  }
}
