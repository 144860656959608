import _ from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router';



const pathMap = {
  '/enterprise/documents?status=draft': '/app/ron/orders',
  '/enterprise/documents?status=pending': '/app/ron/orders',
  '/enterprise/documents?status=completed': '/app/ron/orders',
  '/enterprise/documents?status=canceled': '/app/ron/orders',
  '/enterprise/documents?mySessionsOnly=true': '/app/ron/orders',
  '/enterprise/documents?mySessionsOnly=false': '/app/ron/orders',
  '/enterprise/tagging-room': '/app/ron/tagging-queue',
  '/enterprise/room': '/enterprise/room',
  '/enterprise/notary-queue': '/app/ron/notary-queue',
  '/enterprise/notary': '/app/management/users',
  '/enterprise/documents/edit-notarization': '/app/ron/notary-queue/edit-notarization',
  '/enterprise/documents/order-edit-notarization': '/app/ron/orders/edit-notarization',
  '/enterprise/documents/new-notarization/doc-upload': '/app/ron/orders/new-notarization/doc-upload',
  '/enterprise/documents': '/app/ron/orders',
  '/account/notary-ejournal': '/app/account/notary-ejournal',
}

export function useIframeListener(listener: (event: Record<string, any>) => void) {
  const history = useHistory();

  useEffect(() => {
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    const _handleMessage = (e) => {
      const key = e.message ? 'message' : 'data';
      const data = e[key];
      if (data) {
        if (data.action === 'navigate' && (data.operation === 'push' || data.operation === 'replace')) {

          const pathsToMatch = _.keys(pathMap);
          const pathMatch = _.find(pathsToMatch, (key) => (data.path as string).includes(key));
          if (pathMatch) {
            return history[data.operation]({
              pathname: data.path.replace(pathMatch, pathMap[pathMatch]),
            });
          }
          return history[data.operation]({
            pathname: data.path,
          })
        }
        return listener(data);
      } else {
        console.error('No data received from iframe');
        return null;
      }
    };
    // Listen to the message from child window
    eventer(messageEvent, _handleMessage, false);

    return () => {
      const removeEventMethod = window.removeEventListener ? 'removeEventListener' : 'detachEvent';
      const removeEventer = window[removeEventMethod];
      const removeMessageEvent = removeEventMethod === 'detachEvent' ? 'onmessage' : 'message';

      removeEventer(removeMessageEvent, _handleMessage);
    };
  }, [history, listener]);
}
