import { createSelector } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selector, setSelectedAuditActions } from 'dashboard/src/slices/audit-actions';

const auditSel = createSelector(selector, (state) => state.auditActions.selectedAuditActions);

export default function useAuditActions() {
  const dispatch = useDispatch();
  const selectedAuditActions = useSelector(auditSel);
  const selectAuditActions = useCallback((id: string) => dispatch(setSelectedAuditActions(id)), [dispatch]);

  return {
    selectedAuditActions,
    selectAuditActions,
  }
}
